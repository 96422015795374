import React from "react";
import ffiImage from "assets/images/ffi.png";
import opulImage from "assets/images/opul.png";
import napasImage from "assets/images/napas.png";
import sezibwaImage from "assets/images/sezibwa.png";

export const OurClients = () => {
  return (
    <div id="our-clients" className="mx-8 md:mx-32 my-8">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="md:ml-16 text-4xl mb-4 text-orange-500 font-semibold">
          Our Clients
        </div>
        <div className="flex flex-row items-center space-x-8">
          <a href="" target="_blank" rel="noreferrer">
            <img src={ffiImage} alt="FFI" />
          </a>
          <a href="" target="_blank" rel="noreferrer">
            <img src={opulImage} alt="OPUL" />
          </a>
          <a href="" target="_blank" rel="noreferrer">
            <img src={napasImage} alt="NAPAS" />
          </a>
          <a href="" target="_blank" rel="noreferrer">
            <img src={sezibwaImage} alt="SEZIBWA" />
          </a>
        </div>
      </div>
    </div>
  );
};
