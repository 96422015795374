import React from "react";
import whatWeDoImage from "assets/images/what-we-do.png";
import trainingImage from "assets/images/training.png";
import financingImage from "assets/images/financing-insurance.png";
import agroImage from "assets/images/agro-forestry.png";
import { BsChevronRight } from "react-icons/bs";

export const WhatWeDo = () => {
  return (
    <div id="what-we-do" className="">
      <div className="mx-8 md:mx-32 my-12">
        <div className="md:ml-16 text-4xl mb-12 text-orange-500 font-semibold">
          What We Do
        </div>
      </div>
      <div className="bg-gray-900 p-8 md:w-[50%] text-white text-xl md:text-2xl">
        <div className="mx-8 md:mx-32">
          Farm supplies and last mile delivery
        </div>
      </div>
      <div className="mx-8 md:mx-32 my-12 text-xl md:text-2xl mb-8">
        <div className="grid grid-cols-1 items-center gap-8 md:grid-cols-2 mb-8">
          <img src={whatWeDoImage} alt="" />
          <div className="">
            “All compromise is based on give and take, but there can be no give
            and take on fundamentals. Any compromise on mere fundamentals is a
            surrender. For it is all give and no take.”{" "}
            <span className="text-orange-500">- Mahatma Gandhi</span>
          </div>
        </div>
        <div className="mb-8">
          Quality seed, fertilizer, and farm tools are the fundament basics
          needed for a good harvest. That’s why the quality of AFARM’s products
          matters so much.
        </div>
        <div className="flex flex-row items-center space-x-4 ml-8 mb-8">
          <div>
            {" "}
            <BsChevronRight />
          </div>
          <div>
            We provide farmers with an optimal diverse mix of seed varieties
            (maize, beans, cassava, tree seedlings), animal feeds based on local
            climates at a reflective low cost.
          </div>
        </div>
        <div className="flex flex-row items-center space-x-4 ml-8 mb-8">
          <div>
            <BsChevronRight />
          </div>
          <div>
            We provide quality fertilizer and encourage microdosing — applying
            small quantities of fertilizer close to the seed or plant — for the
            most efficient use possible.
          </div>
        </div>

        <div className="flex flex-row items-center space-x-4 ml-8 mb-8">
          <div>
            <BsChevronRight />
          </div>
          <div>
            We supply everything farmers need through the full planting cycle,
            including farming tools, and harvest products.
          </div>
        </div>

        <div className="flex flex-row items-center space-x-4 ml-8 mb-8">
          <div>
            <BsChevronRight />
          </div>
          <div>
            We distribute seeds and supplies within walking distance of
            farmers’; homes, delivering at just the right time so that farmers
            can get their crops in the ground at the right moment. We deliver
            through two channels: through retail shops and our field officers.
            Where there are no good roads, we deliver using alternative modes of
            transport, like bicycles and motorcycles.
          </div>
        </div>
      </div>

      <div className="bg-gray-900 p-8 md:w-[50%] text-white text-xl md:text-2xl">
        <div className="mx-8 md:mx-32">Training</div>
      </div>

      <div className="mx-8 md:mx-32 my-12 text-xl md:text-2xl mb-8">
        <div className="grid grid-cols-1 items-center gap-8 md:grid-cols-2 mb-8">
          <img src={trainingImage} alt="" />
          <div className="">
            AFARM’s team of field officers provides expert, tailored training
            directly to farmers throughout the year.
          </div>
        </div>
        <div className="mb-8">
          This includes training on land preparation, planting techniques (for
          example, seed spacing and fertiliser micro-dosing), weeding, top-dress
          fertilizer application, pest and disease management, harvesting and
          drying, crop storage and processing, composting, and tree planting.
        </div>

        <div className="mb-8">
          The work we do with farmers doesn’t stop at the first growing cycle.
          Our training is heavily focused on the behavioural changes needed to
          improve soil health over time and build climate resilience, including
          climate-smart techniques in land management, the production and
          application of compost, the use of agricultural lime, and
          intercropping.
        </div>
      </div>

      <div className="bg-gray-900 p-8 md:w-[50%] text-white text-xl md:text-2xl">
        <div className="mx-8 md:mx-32">Financing and Insurance</div>
      </div>

      <div className="mx-8 md:mx-32 my-12 text-xl md:text-2xl mb-8">
        <div className="grid grid-cols-1 items-center gap-8 md:grid-cols-2 mb-8">
          <img src={financingImage} alt="" />
          <div className="">
            We provide the financing needed by farmers to get started whether in
            animal husbandry or crop cultivation, including the direct
            procurement and delivery of farm inputs on credit.
          </div>
        </div>
        <div className="mb-8">
          We purchase farm input insurance on behalf of smallholders to provide
          cover and protect families if extreme weather damages harvests.
        </div>

        <div className="mb-8">
          We directly buy high-value crops from farmers and provide connections
          to markets, eliminating middlemen and increasing farmers' profits.
        </div>
      </div>

      <div className="bg-gray-900 p-8 md:w-[50%] text-white text-xl md:text-2xl">
        <div className="mx-8 md:mx-32">Agro-forestry</div>
      </div>

      <div className="mx-8 md:mx-32 my-12 text-xl md:text-2xl mb-8">
        <div className="grid grid-cols-1 items-center gap-8 md:grid-cols-2 mb-8">
          <img src={agroImage} alt="" />
          <div className="">
            AFARM provides Agro-forestry services, land preparations, tree
            nursery preparations, pruning, thinning and harvesting of
            agro-forestry products.
          </div>
        </div>
      </div>
    </div>
  );
};
