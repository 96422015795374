import React from "react";
import VisionImage from "assets/images/our-vision.png";

export const OurVision = () => {
  return (
    <div id="about-us">
      <div className="mt-12">
        <div className="flex flex-col md:flex-row items-center ml-8 mr-8 md:mr-0 md:ml-32">
          <div className="mb-8 md:mb-0 md:w-3/4">
            <div className="ml-16 text-4xl mb-4 text-orange-500 font-bold">
              Our Vision
            </div>
            <div className="text-xl md:text-2xl">
              <span>
                We envisage Rural smallholder communities in Africa with access
                to better agricultural services such as best-in-class inputs,
                financing, insurance, and training which addresses rural
                poverty.
              </span>
            </div>
          </div>
          <div className="">
            <img src={VisionImage} alt="Our vision" />
          </div>
        </div>
      </div>
    </div>
  );
};
