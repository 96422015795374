import { AnimatedParagraph } from "components/elements";
import React from "react";

export const OurValues = () => {
  return (
    <div className="bg-green-700 mt-12 md:ml-32">
      <span className="text-4xl mb-4 bg-orange-500 p-4 text-white w-fit ml-6 -top-8 font-semibold">
        Our Values
      </span>

      <div className="mt-12 text-xl md:text-2xl text-white p-4 md:p-8">
        <div className="mx-8 md:mr-32">
          <div className="mb-6">
            <span>
              We believe that Agriculture is a key driver of prosperity in the
              rural communities we live in. The core values that drive our staff
              and strategy are;
            </span>
          </div>
          <div className="flex flex-row items-center space-x-2 mb-8">
            <div className="h-12 bg-orange-500 w-2"></div>
            <div className="">
              <span className="text-green-100 font-semibold">Humble Servie</span>
              <br />
              <span className="text-md">
                <span>
                  We serve farmers for their greater good
                </span>
              </span>
            </div>
          </div>

          <div className="flex flex-row items-center space-x-2 mb-8">
            <div className="h-12 bg-orange-500 w-2"></div>
            <div className="">
              <span className="text-green-100 font-semibold">Integrity</span>
              <br />
              <span>
                We do what we say, as agreed with customers for value.
              </span>
            </div>
          </div>

          <div className="flex flex-row items-center space-x-2 mb-8">
            <div className="h-12 bg-orange-500 w-2"></div>
            <div className="">
              <span className="text-green-100 font-semibold">Dreaming Big</span>
              <br />
              <span>We build for scale with every idea and solution.</span>
            </div>
          </div>

          <div className="flex flex-row items-center space-x-2 mb-8">
            <div className="h-12 bg-orange-500 w-2"></div>
            <div className="">
              <span className="text-green-100 font-semibold">Team Work</span>
              <br />
              <span>
                We work together with respect and draw strength from our
                differences
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
