import React from "react";
import ModelImage1 from "assets/images/model-1.png";
import ModelImage2 from "assets/images/model-2.png";
import ModelImage3 from "assets/images/model-3.png";

export const OurModel = () => {
  return (
    <div className="mx-8 md:mx-32 my-12 md:my-24">
      <div className="ml-16 text-4xl mb-4 text-orange-500 font-semibold">
        Our Model
      </div>
      <div className="text-xl md:text-2xl mb-12 md:mb-24">
        <span>
          We supply smallholder farmers with the necessities required for their
          farming success as businesses.
        </span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24 items-center mb-8">
        <div className="">
          <img src={ModelImage1} alt="Model 1" />
        </div>
        <div className="text-xl md:text-2xl">
          <span>
            Farmers approach AFARM when they need quality farm inputs (seeds,
            fertilizers, trees) with cash or credit and access to training on
            planting. Farmers who purchase on credit receive insurance to
            protect their investments from unexpected circumstances.
          </span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24 items-center mb-8">
        <div className="text-xl md:text-2xl">
          <span>
            The farm products are provided through an agency network of over 400
            Agro-dealers to ensure maximum proximity to our farmers.
          </span>
        </div>
        <div className="">
          <img src={ModelImage2} alt="Model 2" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24 items-center mb-8">
        <div className="">
          <img src={ModelImage3} alt="Model 3" />
        </div>
        <div className="text-xl md:text-2xl">
          <span>
            We further provide farmer services such as credit, crop insurance,
            crop purchase (grains, oil seeds, tree crops, root crops), and
            storage during harvest while providing the best returns year-round.
          </span>
        </div>
      </div>
    </div>
  );
};
